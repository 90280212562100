import { Typography, TypographyProps } from '@mui/material';
import React from 'react';

type HighlightedBoldTypographyProps = Omit<TypographyProps, 'children'> & {
  text: string;
  highlights?: string[];
};

const HighlightedBoldTypography = ({
  text,
  highlights = [],
  ...props
}: HighlightedBoldTypographyProps) => {
  // Function to split and highlight the text
  const highlightText = (
    text: string,
    highlights: string[]
  ): React.ReactNode[] => {
    const result: React.ReactNode[] = [];
    let remainingText = text;

    if (highlights.length > 0) {
      highlights.forEach((highlight, index) => {
        const regex = new RegExp(`(${highlight})`, 'gi');
        const parts = remainingText.split(regex);

        result.push(
          ...parts.map((part, partIndex) => (
            <React.Fragment key={`${index}-${partIndex}`}>
              {part.toLowerCase() === highlight.toLowerCase() ? (
                <Typography
                  fontFamily="Inter"
                  color="#000"
                  {...props}
                  component="strong"
                  key={`${index}-${partIndex}`}
                >
                  <strong>{part}</strong>
                </Typography>
              ) : (
                part
              )}
            </React.Fragment>
          ))
        );

        remainingText = parts[parts.length - 1];
      });
    } else {
      result.push(<>{text}</>);
    }

    return result;
  };

  const highlightedParagraph = React.useMemo(
    () => highlightText(text, highlights),
    [text, highlights]
  );

  return (
    <Typography variant="body1" fontFamily="Inter" color="#000" {...props}>
      {highlightedParagraph}
    </Typography>
  );
};

export default HighlightedBoldTypography;
