import { Box, Grid, Stack, Typography } from '@mui/material';
import Image from 'next/image';
import React from 'react';

const Section4: React.FC = () => {
  const boxes = [
    {
      image: '/assets/images/new_home/img4.png',
      title: 'Digitize',
      description:
        'Auto capture data from floor plans, BIM, and sensors to digitally twin your nature assets and track maintenance data on our platform.',
    },
    {
      image: '/assets/images/new_home/img3.png',
      title: 'Configure',
      description:
        'Assess your site’s NbS measure using our module to predict optimal configurations, dynamic pricing estimates, impact outcome glide paths.',
    },
    {
      image: '/assets/images/new_home/img2.png',
      title: 'Manage',
      description:
        'Deploy our service twin tech stack on site to optimize maintenance protocols and bring efficiency into spends for all your nature based assets.',
    },
    {
      image: '/assets/images/new_home/img1.png',
      title: 'Report',
      description:
        'Understand and share your nature based solutions’ performance on site temperature reduction, CO2 footprint, and biodiversity using our Intelligence.',
    },
  ];

  return (
    <Box px={{ xs: 2, md: 14 }}>
      <Grid
        container
        spacing={{ xs: 2, md: 4 }}
        justifyContent="center"
        alignItems="center"
      >
        {boxes.map((box, index) => (
          <Grid item key={index} xs={12} sm={6} height="100%">
            <Stack
              gap={4}
              alignItems="flex-start"
              justifyContent="space-between"
              textAlign="left"
              borderRadius="58px"
              boxShadow="0 0 10px 0 rgba(0, 0, 0, 0.5)"
              py={{ xs: 2, md: 4 }}
              height="100%"
            >
              <Box
                sx={{
                  width: '100%',
                  position: 'relative',
                  paddingTop: { xs: '75%', md: '50%' },
                }}
              >
                <Image
                  src={box.image}
                  alt={box.title}
                  layout="fill"
                  objectFit="contain"
                />
              </Box>

              <Stack
                gap={2}
                padding={{ xs: 4, md: 6 }}
                width="100%"
                height="100%"
              >
                <Typography
                  fontSize="24px"
                  lineHeight="30px"
                  fontWeight={500}
                  color="#000000"
                  fontFamily="Inter"
                >
                  {box.title}
                </Typography>

                <Typography
                  fontSize={{
                    xs: '16px',
                    md: '18px',
                  }}
                  lineHeight={{
                    xs: '28px',
                    md: '30px',
                  }}
                  color="#000000"
                  fontFamily="Inter"
                  fontWeight={300}
                >
                  {box.description}
                </Typography>
              </Stack>
            </Stack>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Section4;
