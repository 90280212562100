import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {
  Box,
  Button,
  Grid,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Image from 'next/image';
import { useRouter } from 'next/router';
import React, { useState } from 'react';

const Section16: React.FC = () => {
  const router = useRouter();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const [currentIndex, setCurrentIndex] = useState(0);

  const boxes = [
    {
      image: '/assets/images/new_home/logo1.svg',
      link: 'https://www.channelnewsasia.com/listen/daily-cuts/letter-myself-getting-roots-nalini-veeraghantas-drive-bring-more-greenery-our-urban-spaces-4787426',
      date: '05 December 2024',
      title:
        "A Letter to Myself: Getting to the roots of Nalini Veeraghanta's drive to bring more greenery into our urban spaces",
    },
    {
      image: '/assets/images/new_home/logo2.svg',
      link: 'https://fintechnews.sg/104266/payments/airwallex-impact/',
      date: '29 November 2024',
      title:
        "Airwallex Impact: Driving Innovation and Philanthropy in Singapore's Fintech Ecosystem - Fintech Singapore",
    },
    {
      image: '/assets/images/new_home/logo6.svg',
      link: 'https://www.herworld.com/pov/what-do-tech-and-nature-have-do-workplace-productivity',
      date: '10 September 2024',
      title: 'What do tech and nature have to do with workplace productivity?',
    },
    {
      image: '/assets/images/new_home/logo3.svg',
      link: 'https://aplussingapore.com/article/nalini-veeraghanta-naturethings',
      date: '06 August 2024',
      title:
        'Nalini Veeraghanta Uses Technology To Integrate Nature Into Urban Spaces',
    },
    {
      image: '/assets/images/new_home/logo4.svg',
      link: 'https://www.edgeprop.sg/property-news/naturethings-marrying-ai-and-greenery-urban-spaces',
      date: '21 June 2024',
      title: 'Naturethings: Marrying AI and greenery in urban spaces ',
    },
  ];

  const visibleCards = isMobile ? 1 : isTablet ? 2 : 3;

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? boxes.length - visibleCards : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex >= boxes.length - visibleCards ? 0 : prevIndex + 1
    );
  };

  return (
    <Stack
      gap={2}
      padding={{ xs: 2, md: 2 }}
      alignItems="center"
      textAlign="center"
    >
      <Stack gap={3} alignItems="center" textAlign="center" width="100%">
        <Typography
          fontSize={{
            xs: '14px',
            md: '20px',
          }}
          lineHeight={{
            xs: '18px',
            md: '28px',
          }}
          fontWeight="bold"
        >
          Naturethings in the news
        </Typography>

        <Stack
          justifyContent="space-between"
          direction="row"
          alignItems="center"
          width="100%"
          gap={2}
          sx={{
            px: { xs: 0, sm: 4 },
          }}
        >
          <IconButton onClick={handlePrev}>
            <ArrowBackIosNewIcon />
          </IconButton>
          <Grid
            container
            spacing={{ xs: 2, md: 4 }}
            justifyContent="space-between"
            alignItems="baseline"
          >
            {boxes
              .slice(currentIndex, currentIndex + visibleCards)
              .map((box, index) => (
                <Grid item key={index} xs={12} md={4} height="100%">
                  <Stack
                    alignItems="flex-start"
                    justifyContent="space-between"
                    textAlign="left"
                    height="100%"
                    gap={2}
                  >
                    <Box
                      sx={{
                        width: '100%',
                        background: '#fff',
                        borderRadius: '30px',
                        height: '150px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Box
                        key={index}
                        sx={{
                          width: { xs: '72px', md: '223px' },
                          height: { xs: '40px', md: '80px' },
                        }}
                      >
                        <Image
                          src={box.image}
                          alt={box.image}
                          width={isMobile ? 72 : 223}
                          height={isMobile ? 40 : 80}
                          style={{
                            objectFit: 'contain',
                            maxWidth: '100%',
                          }}
                        />
                      </Box>
                    </Box>

                    <Stack
                      gap={2}
                      padding={2}
                      width="100%"
                      height="100%"
                      sx={{
                        minHeight: '150px',
                      }}
                    >
                      <Typography
                        fontSize={'13px'}
                        lineHeight={'15px'}
                        fontWeight={300}
                        color="#777777"
                        fontFamily="Inter"
                        sx={{
                          p: 0.5,
                          width: 'fit-content',
                          borderRadius: '10px',
                        }}
                      >
                        {box.date}
                      </Typography>
                      <Typography
                        fontSize={'24px'}
                        lineHeight={'30px'}
                        fontWeight={500}
                        color="#000000"
                        fontFamily="Inter"
                      >
                        {box.title}
                      </Typography>
                      <Typography
                        fontSize={'16px'}
                        lineHeight={'25px'}
                        fontWeight={400}
                        color="#000000"
                        fontFamily="Inter"
                        sx={{
                          textDecoration: 'underline',
                          cursor: 'pointer',
                        }}
                        onClick={() => router.push(box.link)}
                      >
                        View article
                      </Typography>
                    </Stack>
                  </Stack>
                </Grid>
              ))}
          </Grid>
          <IconButton onClick={handleNext}>
            <ArrowForwardIosIcon />
          </IconButton>
        </Stack>

{/*         <Button variant="outlined" color="primary" size="large">
          View All
        </Button> */}
      </Stack>
    </Stack>
  );
};

export default Section16;
