import { Box, Stack, Tab, Tabs, useMediaQuery, useTheme } from '@mui/material';
import Image from 'next/image';
import React, { useEffect } from 'react';

const companyData = [
  {
    key: 'loreal',
    image: '/assets/images/plant_bundles/loreal.png',
    label: 'Fortune 500',
  },
  {
    key: 'inmobi',
    image: '/assets/images/plant_bundles/inmobi.png',
    label: 'Technology',
  },
  {
    key: 'sm',
    image: '/assets/images/plant_bundles/sm.png',
    label: 'Developers',
  },
  {
    key: 'hospitality',
    image: '/assets/images/plant_bundles/hospitality.png',
    label: 'Hospitality',
  },
  {
    key: 'gbb',
    image: '/assets/images/plant_bundles/gbb.png',
    label: 'Parks',
  },
];

export default function Section7() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [value, setValue] = React.useState(0);

  const delay = 2000;
  const totalTabs = companyData.length;

  useEffect(() => {
    let currentTab = 0;

    const interval = setInterval(() => {
      setValue(currentTab);
      currentTab++;

      if (currentTab === totalTabs) {
        currentTab = 0;
      }
    }, delay);

    return () => {
      clearInterval(interval); // Clean up the interval when the component unmounts
    };
  }, [totalTabs, delay]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Stack gap={{ xs: 2, md: 4 }} alignItems="center">
      <Box sx={{ width: '100%' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant={isMobile ? 'scrollable' : 'fullWidth'} // Adjust variant based on screen size
          sx={{
            color: '#000',
            opacity: 1,
            '& .MuiTabs-indicator': {
              backgroundColor: '#000',
            },
            '& .Mui-selected': {
              color: '#000',
              fontWeight: '600',
            },
          }}
          centered={!isMobile}
        >
          {companyData.map((item, index) => (
            <Tab
              label={item.label}
              value={index}
              key={item.key}
              sx={{
                fontFamily: 'Inter',
                textTransform: 'capitalize',
                paddingBottom: 0,
                fontWeight: 400,
                fontSize: '18px',
                lineHeight: '28px',
              }}
            />
          ))}
        </Tabs>
      </Box>

      <Image
        height={160}
        width={290}
        src={companyData[value].image}
        alt={companyData[value].key}
      />
    </Stack>
  );
}
