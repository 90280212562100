import { Stack, Typography } from '@mui/material';
import React from 'react';
import HighlightedBoldTypography from './HighlightedBoldTypography';

const Section3: React.FC = () => {
  return (
    <Stack
      gap={2}
      padding={{ xs: 2, md: 6 }}
      alignItems="center"
      textAlign="center"
    >
      <Typography
        color="#F44174"
        fontSize={{
          xs: '14px',
          md: '20px',
        }}
        lineHeight={{
          xs: '18px',
          md: '28px',
        }}
        fontWeight="bold"
      >
        Our Technology
      </Typography>

      <Typography
        fontSize={{
          xs: '30px',
          md: '40px',
        }}
        lineHeight={{
          xs: '40px',
          md: '48px',
        }}
        fontWeight={600}
        fontFamily="Inter"
        color="#000"
      >
        Urban Nature Management Platform
      </Typography>

      <Stack
        gap={3}
        width={{ xs: '100%', md: '52%' }}
        alignItems="center"
        textAlign="center"
      >
        <HighlightedBoldTypography
          sx={{
            fontWeight: 300,
          }}
          fontSize={{
            xs: '18px',
            md: '24px',
          }}
          lineHeight={{
            xs: '30px',
            md: '40px',
          }}
          text="An AI, IoT-enabled Naturetech platform for owners, operators and service providers to leverage  plan and manage their key outcomes on their site."
          highlights={['owners, operators and service providers']}
        />

        {/* <Button variant="outlined" color="primary" size="large">
          Learn More
        </Button> */}
      </Stack>
    </Stack>
  );
};

export default Section3;
