import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {
  Box,
  Button,
  Grid,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Image from 'next/image';
import { useRouter } from 'next/router';
import React, { useState } from 'react';

const Section15: React.FC = () => {
  const router = useRouter();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const [currentIndex, setCurrentIndex] = useState(0);

  const boxes = [
    {
      image: '/assets/images/new_home/article1.png',
      link: 'https://naturethings.notion.site/Measuring-and-optimising-a-Hospitality-project-s-Nature-based-Solutions-NbS-for-cost-efficiency-s-1816c79561c380f689b7e8b8db4a55f7?pvs=4',
      tag: 'Case Study',
      title: 'Measuring and optimising a hospitality project’s Nature-based Solutions (NbS) for cost efficiency and sustainability impact',
    },
    {
      image: '/assets/images/new_home/article2.png',
      link: 'https://naturethings.notion.site/Planning-and-optimising-a-Corporate-project-s-Nature-based-Solution-NbS-for-wellbeing-cost-effici-1816c79561c38074961cd9bf1ed14475',
      tag: 'Case Study',
      title: 'Planning and optimising a corporate project’s Nature based Solution (NbS) for wellbeing and cost efficiency',
    },
    // {
    //   image: '/assets/images/new_home/article3.png',
    //   link: 'https://www.channelnewsasia.com/listen/daily-cuts/letter-myself-getting-roots-nalini-veeraghantas-drive-bring-more-greenery-our-urban-spaces-4787426',
    //   tag: 'Blog',
    //   title: 'Naturethings wins AirWallex Level-Up Business Grant',
    // },
  ];

  const visibleCards = isMobile ? 1 : isTablet ? 2 : 3;

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? boxes.length - visibleCards : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex >= boxes.length - visibleCards ? 0 : prevIndex + 1
    );
  };

  return (
    <Stack
      gap={2}
      padding={{ xs: 2, md: 2 }}
      alignItems="center"
      textAlign="center"
    >
      <Stack gap={3} alignItems="center" textAlign="center" width="100%">
        <Typography
          fontSize={{
            xs: '14px',
            md: '20px',
          }}
          lineHeight={{
            xs: '18px',
            md: '28px',
          }}
          fontWeight="bold"
        >
          Recent Articles
        </Typography>

        <Stack
          justifyContent="space-between"
          direction="row"
          alignItems="center"
          width="100%"
          gap={2}
          sx={{
            px: { xs: 0, sm: 4 },
          }}
        >
          <IconButton onClick={handlePrev}>
            <ArrowBackIosNewIcon />
          </IconButton>
          <Grid
            container
            spacing={{ xs: 2, md: 4 }}
            justifyContent="space-between"
            alignItems="baseline"
          >
            {boxes
              .slice(currentIndex, currentIndex + visibleCards)
              .map((box, index) => (
                <Grid
                  item
                  key={index}
                  xs={12}
                  md={4}
                  height="100%"
                  onClick={() => router.push(box.link)}
                >
                  <Stack
                    alignItems="flex-start"
                    justifyContent="space-between"
                    textAlign="left"
                    height="100%"
                  >
                    <Box
                      sx={{
                        width: '100%',
                        position: 'relative',
                        paddingTop: { xs: '60%', md: '60%' },
                      }}
                    >
                      <Image
                        src={box.image}
                        alt={box.title}
                        layout="fill"
                        objectFit="contain"
                      />
                    </Box>

                    <Stack
                      gap={2}
                      padding={2}
                      width="100%"
                      height="100%"
                      sx={{
                        background: '#fff',
                        borderBottomLeftRadius: '30px',
                        borderBottomRightRadius: '30px',
                        pt: 4,
                        pb: 8,
                        minHeight: '250px',
                      }}
                    >
                      <Typography
                        fontSize={'13px'}
                        lineHeight={'15px'}
                        fontWeight={500}
                        color="#000000"
                        fontFamily="Inter"
                        sx={{
                          background: '#D2FC5C',
                          p: 0.5,
                          width: 'fit-content',
                          borderRadius: '10px',
                        }}
                      >
                        {box.tag}
                      </Typography>
                      <Typography
                        fontSize={'24px'}
                        lineHeight={'30px'}
                        fontWeight={500}
                        color="#000000"
                        fontFamily="Inter"
                      >
                        {box.title}
                      </Typography>
                    </Stack>
                  </Stack>
                </Grid>
              ))}
          </Grid>
          <IconButton onClick={handleNext}>
            <ArrowForwardIosIcon />
          </IconButton>
        </Stack>

{/*         <Button variant="outlined" color="primary" size="large">
          Browse all posts
        </Button> */}
      </Stack>
    </Stack>
  );
};

export default Section15;
