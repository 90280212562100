import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
export const FacebookLink = () => (
  <a
    href="https://www.facebook.com/getnaturethings"
    target="_blank"
    rel="noreferrer"
  >
    <FacebookIcon
      sx={{
        p: 1,
        border: '1px solid #fff',
        borderRadius: '50%',
        color: '#fff',
      }}
      fontSize="large"
    />
  </a>
);
export const LinkedinLink = () => (
  <a
    href="https://www.linkedin.com/company/getnaturethings/"
    target="_blank"
    rel="noreferrer"
  >
    <LinkedInIcon
      sx={{
        p: 1,
        border: '1px solid #fff',
        borderRadius: '50%',
        color: '#fff',
      }}
      fontSize="large"
    />
  </a>
);
export const InstagramLink = () => (
  <a
    href="https://www.instagram.com/getnaturethings/"
    target="_blank"
    rel="noreferrer"
  >
    <InstagramIcon
      sx={{
        p: 1,
        border: '1px solid #fff',
        borderRadius: '50%',
        color: '#fff',
      }}
      fontSize="large"
    />
  </a>
);
export const SocialLinks = () => {
  return (
    <>
      <FacebookLink />
      <InstagramLink />
      <LinkedinLink />
    </>
  );
};
