import {
  Box,
  Button,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useRouter } from 'next/router';
import React from 'react';
import Typewriter from 'typewriter-effect';

import HighlightedBackgroundTypography from './HighlightedBackgroundTypography';
import HighlightedBoldTypography from './HighlightedBoldTypography';

const Section1: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const router = useRouter();

  return (
    <Grid
      container
      alignItems="flex-start"
      justifyContent="flex-start"
      sx={{
        height: {
          xs: 'auto',
          md: '1170px',
        },
        backgroundImage: {
          xs: 'none',
          sm: 'url(/assets/images/new_home/home_desktop_bg1.png)',
        },
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        pt: { xs: 2, md: 0 },
      }}
    >
      <Grid
        item
        xs={12}
        md={5}
        padding={{
          xs: 2,
          md: 8,
        }}
        pt={{
          xs: 4,
          md: 22,
        }}
        px={{
          xs: 6,
          md: 8,
        }}
        sx={{
          textAlign: { xs: 'left', md: 'left' },
        }}
      >
        <Stack gap={4}>
          <Typography
            fontWeight="bold"
            fontSize={{
              xs: '30px',
              md: '48px',
            }}
            lineHeight={{
              xs: '40px',
              md: '60px',
            }}
            variant="body1"
            fontFamily="Inter"
            color="#000"
          >
            <span>Unlocking your building’s</span>
            <span
              style={{
                backgroundColor: '#D1FE4E',
                display: 'table-cell',
              }}
            >
              <Typewriter
                onInit={(typewriter) => {
                  typewriter
                    .typeString('sustainability')
                    .pauseFor(1000)
                    .deleteAll()
                    .typeString('well-being')
                    .pauseFor(1000)
                    .deleteAll()
                    .typeString('cost efficiency')
                    .pauseFor(1000)
                    .start();
                }}
                options={{ loop: true }}
              />
            </span>
            <span> with nature</span>
          </Typography>

          {!isMobile && (
            <Stack gap={3}>
              <HighlightedBoldTypography
                fontSize="18px"
                lineHeight="30px"
                text="Harness the power of digitizing and transforming your urban environment with our AI-IoT platform, enhancing the potential of your building, site, or modular real estate space."
                highlights={['AI-IoT platform']}
              />
              <Box>
                <Button
                  variant="contained"
                  color="secondary"
                  sx={{
                    borderRadius: '58px',
                    fontWeight: 600,
                    color: '#D1FE4E',
                    background: '#000',
                  }}
                  size="large"
                  onClick={() => router.push('/contact_us')}
                >
                  Book a Demo
                </Button>
              </Box>
            </Stack>
          )}
        </Stack>
      </Grid>

      {isMobile && (
        <Grid
          item
          xs={12}
          sm={0}
          sx={{
            width: '100%',
            textAlign: 'center',
            height: '460px',
            backgroundImage: 'url(/assets/images/new_home/home_mobile_bg2.png)',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box>
            <HighlightedBackgroundTypography
              fontSize="18px"
              lineHeight="30px"
              text="Harness the power of digitizing and transforming your urban environment with our AI-IoT platform, enhancing the potential of your building, site, or modular real estate space."
              highlights={['AI-driven IoT platform']}
              sx={{ marginTop: '-240px', paddingX: '20px' }}
            />
            <Button
              variant="contained"
              color="secondary"
              sx={{
                borderRadius: '58px',
                fontWeight: 600,
                color: '#D1FE4E',
                background: '#000',
                marginTop: '20px',
              }}
              onClick={() => router.push('/contact_us')}
            >
              Book a Demo
            </Button>
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

export default Section1;
