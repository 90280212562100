import {
  Box,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Image from 'next/image';
import React from 'react';
import HighlightedBackgroundTypography from './HighlightedBackgroundTypography';

const Section11: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const boxes = [
    {
      image: '/assets/images/new_home/Tree-1--Streamline-Plump.svg',
      title: 'Real time data of your urban spaces',
      highlight: ['Real time data'],
      description:
        'Stay instantly informed about the health and functionality of your urban environments, enabling quick decision-making and timely interventions to optimize sustainability efforts.',
    },
    {
      image: '/assets/images/new_home/Search-Visual--Streamline-Plump.svg',
      title: 'Granular and actionable insights',
      highlight: ['insights'],
      description:
        'Gain a deep understanding of your urban spaces, empowering you to implement targeted improvements that meet Green standards,  maximizing impact and efficiency.',
    },
    {
      image: '/assets/images/new_home/Browser-Code-1--Streamline-Plump.svg',
      title: 'Urban space transformation aligned with ESG standards',
      highlight: ['ESG standards'],
      description:
        "Enhance your urban projects' sustainability and social impact while ensuring compliance with ESG benchmarks, boosting both environmental and reputational value.",
    },
    {
      image: '/assets/images/new_home/Share-Link--Streamline-Plump.svg',
      title: 'Automated reporting accessible at anytime',
      highlight: ['reporting'],
      description:
        'Save time with instantly generated reports that provide a comprehensive overview of your efforts, keeping stakeholders updated and streamlining your ESG reporting process.',
    },
  ];

  return (
    <Box px={{ xs: 2, md: 8 }}>
      <Grid
        container
        spacing={isMobile ? 2 : 4}
        justifyContent="center"
        alignItems="center"
      >
        {boxes.map((box, index) => (
          <Grid item key={index} xs={12} sm={6} sx={{}}>
            <Stack
              gap={4}
              alignItems="flex-start"
              justifyContent="space-between"
              textAlign="left"
              borderRadius={'58px'}
              padding={4}
              border={'1px solid #C3C3C3'}
              pb={{ xs: 4, md: 6 }}
            >
              <Image
                src={box.image}
                alt={box.title}
                width={75}
                height={75}
                layout="contain"
                objectFit="cover"
              />

              <Stack gap={2}>
                <Box width={{ xs: '80%', md: '50%' }}>
                  <HighlightedBackgroundTypography
                    fontSize="24px"
                    lineHeight="30px"
                    fontWeight={500}
                    text={box.title}
                    highlights={box.highlight}
                  />
                </Box>

                <Typography
                  fontSize={{
                    xs: '16px',
                    md: '18px',
                  }}
                  lineHeight={{
                    xs: '28px',
                    md: '30px',
                  }}
                  fontWeight={300}
                  fontFamily="Inter"
                  color="#000"
                >
                  {box.description}
                </Typography>
              </Stack>
            </Stack>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Section11;
