import { Container, Grid, Link, Typography } from '@mui/material';
import { useTranslation } from 'next-i18next';
import { SocialLinks } from '../SocialLinks';

const styles = {
  link: {
    opacity: 1,
    cursor: 'pointer',
  },
};

const Footer = () => {
  const { t } = useTranslation();

  const linkProps = {
    mt: 1,
    color: '#5C5C5C',
    component: Link,
    sx: styles.link,
  };

  const link1 = {
    row1: [
      {
        label: 'Home',
        href: '/',
      },
      {
        label: t('about'),
        href: '/',
      },
    ],
    // row2: [
    //   { href: "/articles", label: t("view_articles") },
    //   { href: "/contact_us", label: t("seller_signup") },
    //   { href: "/contact_us", label: t("corporate_enquiries") },
    //   { href: "/contact_us", label: t("contact_us") },
    // ],
  };

  const link2 = {
    row1: [
      {
        label: t('contact_us'),
        href: '/contact_us',
      },
      {
        label: t('terms_of_use'),
        href: '/terms_of_use',
      },
      {
        label: t('privacy_policy'),
        href: '/privacy_policy',
      },
    ],
  };

  return (
    <Container
      sx={{
        padding: '50px !important',
        backgroundColor: '#1A1A1B',
      }}
      maxWidth={false}
    >
      <Grid container justifyContent={'space-between'} spacing={3}>
        <Grid
          container
          item
          xs={12}
          md={4}
          direction={'column'}
          sx={{
            alignContent: { xs: 'center', md: 'left' },
          }}
        >
          {/* <Image
            src="/assets/images/nt_logo.svg"
            width={40}
            height={40}
            layout="fixed"
            alt={t('nature_things')}
          /> */}

          <Grid
            mt={4}
            container
            sx={{ columnGap: 1, display: { xs: 'none', md: 'flex' } }}
          >
            <SocialLinks />
          </Grid>
        </Grid>
        <Grid container item xs={6} md={4} direction={'column'}>
          {/* <Typography
            sx={{
              mt: 1,
              color: '#fff',
            }}
            fontWeight={500}
            variant="subtitle1"
          >
            Dashboard
          </Typography> */}
          {link1.row1.map((link, index) => (
            <Typography href={link.href} {...linkProps} key={index}>
              {link.label}
            </Typography>
          ))}
        </Grid>
        <Grid container item xs={6} md={4} direction={'column'}>
          {/* <Typography
            sx={{
              mt: 1,
              color: '#fff',
            }}
            fontWeight={500}
            variant="subtitle1"
          >
            Direct Links
          </Typography> */}
          {link2.row1.map((link, index) => (
            <Typography href={link.href} {...linkProps} key={index}>
              {link.label}
            </Typography>
          ))}
        </Grid>

        {/*<Grid container item xs={6} md={2} direction={"column"}>*/}
        {/*  {links.row2.map((link, index) => (*/}
        {/*    <Typography href={link.href} {...linkProps} key={index}>*/}
        {/*      {link.label}*/}
        {/*    </Typography>*/}
        {/*  ))}*/}
        {/*</Grid>*/}

        <Grid
          item
          xs={12}
          md={0}
          mt={4}
          container
          justifyContent="center"
          textAlign={'center'}
          gap={1}
          sx={{ display: { xs: 'flex', md: 'none' } }}
        >
          <SocialLinks />
        </Grid>

        <Grid
          item
          xs={12}
          container
          alignSelf={'center'}
          sx={{
            borderTop: '1px solid #5C5C5C',
            justifyContent: 'center',
            mt: 4,
          }}
        >
          <Typography
            sx={{
              color: '#5C5C5C',
            }}
          >
            © All Nature Things Pte Ltd
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Footer;
