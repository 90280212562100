import { Grid, Stack, Typography } from '@mui/material';
import React from 'react';

import HighlightedBoldTypography from './HighlightedBoldTypography';

const Section14: React.FC = () => {
  const boxes = [
    {
      tag: '♽',
      title: 'Sustainability mandates & ESG compliance',
      description:
        'Accelerated by carbon & nature financial disclosures, aligning with sustainability goals to remain compliant and competitive. ',
    },
    {
      tag: '🏢',
      title: 'Occupant & End user demands',
      description:
        'Increasing guest satisfaction to employee productivity - Tenants are willing to pay a 5- 15% premium for healthier, greener spaces that influence AQI, Wellbeing,  Biodiversity',
    },
    {
      tag: '📈',
      title: 'Operational cost pressure',
      description:
        'Greener buildings and technology reduce operational costs by up to 30% due to energy efficiency and resource optimization.',
    },
    {
      tag: '💰',
      title: 'Access to greener capital',
      description:
        'Preferential Mortgages, Tax breaks, credits, Green-certified buildings receive 20-25% more financing approvals globally.',
    },
  ];

  return (
    <Stack
      gap={2}
      padding={{ xs: 2, md: 2 }}
      alignItems="center"
      textAlign="center"
    >
      <Stack
        gap={3}
        width={{ xs: '100%', md: '70%' }}
        alignItems="center"
        textAlign="center"
      >
        <HighlightedBoldTypography
          sx={{
            fontWeight: 300,
          }}
          fontSize={{
            xs: '22px',
            md: '24px',
          }}
          lineHeight={{
            xs: '30px',
            md: '40px',
          }}
          text="The science is indisputable : Urban Nature based solutions (Nbs) are critical for buildings to cost efficiently manage decarbonisation, occupant wellbeing. Gain your competitive edge today."
          highlights={['Nature based solutions']}
        />

        <Typography
          sx={{
            fontWeight: 500,
          }}
          fontSize={{
            xs: '22px',
            md: '24px',
          }}
          lineHeight={{
            xs: '30px',
            md: '40px',
          }}
          color="#F44174"
        >
          Why invest in measurable NbS ?
        </Typography>

        <Grid
          container
          spacing={{ xs: 2, md: 4 }}
          justifyContent="space-between"
          alignItems="stretch"
          height="100%"
        >
          {boxes.map((box, index) => (
            <Grid item key={index} xs={6} md={3}>
              <Stack
                gap={4}
                alignItems="flex-start"
                justifyContent="flex-start"
                textAlign="center"
                height="100%"
                sx={{
                  background: '#F3F3F3',
                  borderRadius: '12px',
                }}
              >
                <Stack
                  gap={2}
                  padding={2}
                  paddingTop={4}
                  width="100%"
                  height="100%"
                >
                  <Stack gap={1}>
                    <Typography
                      fontSize={{
                        xs: '12px',
                        md: '20px',
                      }}
                      lineHeight={{
                        xs: '15px',
                        md: '25px',
                      }}
                      fontWeight={500}
                      color="#000000"
                      fontFamily="Inter"
                    >
                      {box.tag}
                    </Typography>
                    <Typography
                      fontSize={{
                        xs: '12px',
                        md: '20px',
                      }}
                      lineHeight={{
                        xs: '15px',
                        md: '25px',
                      }}
                      fontWeight={500}
                      color="#000000"
                      fontFamily="Inter"
                    >
                      {box.title}
                    </Typography>
                  </Stack>
                  <Typography
                    fontSize={{
                      xs: '10px',
                      md: '15px',
                    }}
                    lineHeight={{
                      xs: '15px',
                      md: '25px',
                    }}
                    color="#000000"
                    fontFamily="Inter"
                    fontWeight={300}
                  >
                    {box.description}
                  </Typography>
                </Stack>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Stack>
    </Stack>
  );
};

export default Section14;
