import { Grid, Stack, Typography, useTheme } from '@mui/material';

export default function Section9() {
  const theme = useTheme();
  const data = [
    {
      title: '1.5 mn+',
      subtitle: 'sqft of built area captured ',
    },
    {
      title: '8%',
      subtitle: 'average green area footprint managed',
    },
    {
      title: '700+',
      subtitle: 'people have daily access to nature',
    },
    {
      title: '>10%',
      subtitle: 'average cost efficiency delivered',
    },
  ];
  return (
    <Stack px={{ xs: 1, md: 28 }} mr={{ xs: -2, md: 0 }}>
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        spacing={3}
        padding={4}
        sx={{
          borderRadius: { xs: 0, md: '58px' },
          backgroundColor: '#1A1A1B',
          boxShadow: { xs: 'unset', md: '0 0 19.3px 0 #cbff00' },
          textAlign: { xs: 'center', md: 'left' },
        }}
      >
        <Grid item xs={12} textAlign="center">
          <Typography
            sx={{
              fontSize: '16px',
              lineHeight: '20px',
              color: '#cbff00',
            }}
            fontWeight={500}
            fontFamily={'Inter'}
          >
            Our Impact
          </Typography>
        </Grid>
        {data.map((item, index) => (
          <Grid
            item
            xs={12}
            md={3}
            padding={4}
            sx={{
              position: 'relative',
              [theme.breakpoints.down('md')]: {
                '&:not(:first-child)::before': {
                  content: '""',
                  position: 'absolute',
                  top: 0,
                  left: '50%',
                  transform: 'translateX(-50%)',
                  width: '80px',
                  height: '0.5px',
                  borderTop: '0.5px solid #afafaf',
                },
                '&:not(:last-child)::after': {
                  content: '""',
                  position: 'absolute',
                  bottom: 0,
                  left: '50%',
                  transform: 'translateX(-50%)',
                  width: '80px',
                  height: '0.5px',
                  borderBottom: '0.5px solid #afafaf',
                },
              },
              [theme.breakpoints.up('md')]: {
                '&::before, &::after': {
                  content: 'none',
                },
              },
            }}
            key={index}
          >
            <Stack gap={2}>
              <Typography
                sx={{
                  fontSize: '40px',
                  lineHeight: '40px',
                  fontWeight: 'bold',
                  color: '#cbff00',
                }}
              >
                {item.title}
              </Typography>

              <Typography
                sx={{
                  fontSize: '16px',
                  lineHeight: '20px',
                  color: '#FFFFFF',
                }}
                fontWeight={500}
                fontFamily={'Inter'}
              >
                {item.subtitle}
              </Typography>
            </Stack>
          </Grid>
        ))}
      </Grid>
    </Stack>
  );
}
