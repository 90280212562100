import { Typography, TypographyProps } from '@mui/material';
import React from 'react';

type HighlightedBackgroundTypographyProps = Omit<
  TypographyProps,
  'children'
> & {
  text: string;
  highlights?: string[];
  highlightColor?: string;
};

const escapeRegExp = (text: string) =>
  text.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

const HighlightedBackgroundTypography = ({
  text,
  highlights = [],
  highlightColor = '#D1FE4E',
  ...props
}: HighlightedBackgroundTypographyProps) => {
  // Function to split and highlight the text
  const highlightText = (
    text: string,
    highlights: string[]
  ): React.ReactNode[] => {
    const result: React.ReactNode[] = [];
    let remainingText = text;

    highlights.forEach((highlight, index) => {
      const escapedHighlight = escapeRegExp(highlight);
      const regex = new RegExp(`(${escapedHighlight})`, 'gi');
      const parts = remainingText.split(regex);

      result.push(
        ...parts.map((part, partIndex) => (
          <React.Fragment key={`${index}-${partIndex}`}>
            {part.toLowerCase() === highlight.toLowerCase() ? (
              <Typography
                fontFamily="Inter"
                color="#000"
                {...props}
                component="span"
                sx={{
                  ...(props.sx || {}),
                  backgroundColor: highlightColor,
                }}
                key={`${index}-${partIndex}`}
              >
                {part}
              </Typography>
            ) : (
              part
            )}
          </React.Fragment>
        ))
      );

      remainingText = parts[parts.length - 1];
    });

    return result;
  };

  const highlightedParagraph = React.useMemo(
    () => highlightText(text, highlights),
    [text, highlights]
  );

  return (
    <Typography variant="body1" fontFamily="Inter" color="#000" {...props}>
      {highlightedParagraph}
    </Typography>
  );
};

export default HighlightedBackgroundTypography;
