import { Box, Button, Stack } from '@mui/material';
import { useRouter } from 'next/router';
import React from 'react';
import HighlightedBoldTypography from './HighlightedBoldTypography';

const Section5: React.FC = () => {
  const router = useRouter();

  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{
        backgroundImage: 'url(/assets/images/new_home/home_desktop_bg2.png)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        width: '100%',
        height: '430px',
      }}
    >
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{
          p: { xs: 4, md: 8 },
          textAlign: 'center',
          width: { xs: '100%', md: '55%' },
        }}
        gap={5}
      >
        <HighlightedBoldTypography
          fontSize={{
            xs: '28px',
            md: '32px',
          }}
          lineHeight={{
            xs: '44px',
            md: '50px',
          }}
          color="#fff"
          fontFamily="Inter"
          text="Is your nature optimal? Unlock the full potential of your urban spaces."
          highlights={['Unlock the full potential']}
        />

        <Box>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            sx={{
              borderRadius: '58px',
              fontWeight: 600,
              color: '#000',
            }}
            onClick={() => router.push('/contact_us')}
          >
            Book a demo
          </Button>
        </Box>
      </Stack>
    </Stack>
  );
};

export default Section5;
