import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {
  Box,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Image from 'next/image';
import React, { useState } from 'react';

const Section8: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const [currentIndex, setCurrentIndex] = useState(0);

  const cards = [
    {
      image: '/assets/images/new_home/image_90.png',
      message:
        'Super quick delivery and healthy plants are great, but i really love the AI chat bot for the maintenance support. Really innovative and user friendly. Makes it easy to identify any issues with my plants.',
      name: 'Bjorn',
      designation: 'Founder - EOC',
    },
    {
      image: '/assets/images/new_home/image_107.png',
      message:
        'naturethings is a remarkable startup that shares our vision of transforming urban workspaces with a touch of nature. Their solution addresses the problem of high cost of acquiring and maintaining live plants by combining nature, AI-technology to create a sustainable spaces.',
      name: 'Guarav Joshi',
      designation: 'Co-Founder - The Work Boulevard',
    },
    {
      image: '/assets/images/new_home/image_108.png',
      message:
        'Impressive. I have recommended the app to tenants and also house plants lovers to help them track the maintenance routine and plant health.',
      name: 'LiangFa L',
      designation: 'Dy. Director - Gardens by the Bay',
    },
  ];

  const visibleCards = isMobile ? 1 : isTablet ? 2 : 3;

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? cards.length - visibleCards : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex >= cards.length - visibleCards ? 0 : prevIndex + 1
    );
  };

  return (
    <Box px={{ xs: 0, md: 6 }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative',
          px: { xs: 0, sm: 4 },
        }}
      >
        <IconButton onClick={handlePrev} sx={{ position: 'absolute', left: 0 }}>
          <ArrowBackIosNewIcon />
        </IconButton>
        <Stack
          direction="row"
          justifyContent="space-evenly"
          alignItems="center"
          gap={{ xs: 0, sm: 4 }}
          overflow="hidden"
        >
          {cards
            .slice(currentIndex, currentIndex + visibleCards)
            .map((card, index) => (
              <Stack
                key={index}
                sx={{
                  flex: '0 0 auto',
                  textAlign: 'left',
                  padding: 4,
                  py: 6,
                  transition: 'all 0.5s ease-in-out',
                  backgroundColor: '#F3F3F3',
                  borderRadius: '58px',
                  minHeight: { xs: '400px', sm: '500px' },
                  width: '350px',
                }}
                gap={2}
                justifyContent="flex-start"
                textAlign="left"
              >
                <Box
                  sx={{
                    width: '150px',
                    height: '150px',
                    margin: '0 auto',
                  }}
                >
                  <Image
                    src={card.image}
                    alt={card.name}
                    width={150}
                    height={150}
                    layout="contain"
                    objectFit="cover"
                  />
                </Box>
                <Typography
                  fontSize={{
                    xs: '13px',
                    md: '14px',
                  }}
                  fontWeight={400}
                  lineHeight="20px"
                  sx={{
                    wordBreak: 'break-word',
                    whiteSpace: 'pre-line',
                  }}
                  color="#000000"
                  fontFamily="Inter"
                >
                  {`“
                  ${card.message}
                  ”`}
                </Typography>

                <Typography
                  fontSize="14px"
                  fontWeight="bold"
                  lineHeight="20px"
                  color="#000000"
                  fontFamily="Inter"
                >
                  {card.name}
                </Typography>

                <Typography
                  fontSize="14px"
                  fontWeight={300}
                  lineHeight="20px"
                  color="#000000"
                  fontFamily="Inter"
                >
                  {card.designation}
                </Typography>
              </Stack>
            ))}
        </Stack>
        <IconButton
          onClick={handleNext}
          sx={{ position: 'absolute', right: 0 }}
        >
          <ArrowForwardIosIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default Section8;
