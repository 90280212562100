import { Box, Stack, Typography } from '@mui/material';
import React from 'react';
import HighlightedBoldTypography from './HighlightedBoldTypography';

const Section6: React.FC = () => {
  return (
    <Stack
      gap={2}
      px={{ xs: 2, md: 19 }}
      alignItems="center"
      textAlign="center"
    >
      <Typography
        color="#F44174"
        fontSize={{
          xs: '14px',
          md: '20px',
        }}
        lineHeight={{
          xs: '18px',
          md: '28px',
        }}
        fontWeight="bold"
      >
        Why Naturethings
      </Typography>

      <Box width={{ xs: '100%', md: '60%' }}>
        <HighlightedBoldTypography
          fontSize={{ xs: '28px', md: '32px' }}
          lineHeight={{ xs: '44px', md: '50px' }}
          fontWeight={500}
          text="Trusted by real estate leaders to transform their nature dollars to cost
        efficient impact on our platform"
          highlights={[]}
        />
      </Box>
    </Stack>
  );
};

export default Section6;
