import { useQuery } from '@apollo/client';
import { GET_ORG_PLAN_DATA } from '@operations/user/queries';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { destroyCookie } from 'nookies';
import { useContext, useState } from 'react';

import {
  Button,
  IconButton,
  Link,
  Menu,
  MenuItem as MuiMenuItem,
  useMediaQuery,
} from '@mui/material';
import Image from 'next/image';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { useTheme } from '@mui/material/styles';

import styled from '@emotion/styled';

import { logout } from '@operations/auth';
import { AuthDialogContext } from '../../../contexts/AuthDialogContext';
import { GET_USER_ORG } from '../../../operations/user/queries';

const MenuItem = styled(MuiMenuItem)`
  font-weight: bold;
  font-size: 15px;
`;

const AvatarIconButton = styled(IconButton)`
  border-radius: 12px;
  background-color: transparent;
`;

export default function AccountMenu() {
  const { t } = useTranslation('common');

  const { data: { getOrgSubscriptionInfo: planData } = {} } = useQuery(
    GET_ORG_PLAN_DATA,
    {
      fetchPolicy: 'network-only',
    }
  );

  const { data: { userOrg: orgData } = {} } = useQuery(GET_USER_ORG, {
    fetchPolicy: 'network-only',
  });

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const router = useRouter();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const { setAuthDialog } = useContext(AuthDialogContext);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    await logout();
    destroyCookie(null, 'token');
    setAuthDialog(false);
    router.push('/login'); // redirecting to login
  };

  const menuItems = [
    {
      label: t('my_profile'),
      path: '/org/profile',
    },
    {
      label: t('dashboard'),
      path: `/org/dashboard?spaceId=${orgData?.spaceId}`,
    },
    // {
    //   label: t("digital_garden"),
    //   path: "/org/plants",
    // },
    // {
    //   label: t("maintenance"),
    //   path: "/org/dashboard",
    //   enabled: "NO",
    // },
    // {
    //   label: t("rewards"),
    //   path: "/org/dashboard",
    //   enabled: "NO",
    // },
    // {
    //   label: t("plan"),
    //   path: "/org/plan",
    // },
    {
      label: t('files'),
      path: '/org/files',
      enabled: 'YES',
    },
  ];

  return (
    <>
      <AvatarIconButton onClick={handleClick} size="small">
        <Image
          width={20}
          height={20}
          src="/assets/images/profile_menu/profile_icon.svg"
          alt="Profile"
        />
        <KeyboardArrowDownIcon />
      </AvatarIconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            padding: '9px 11px',
            borderRadius: '12px',
            boxShadow: '0 12px 24px 0 rgba(0, 0, 0, 0.05)',
            border: 'solid 2px #e3e3e3',
            backgroundColor: '#fff',
          },
        }}
        transformOrigin={{
          horizontal: isMobile ? 'center' : 'right',
          vertical: 'top',
        }}
        anchorOrigin={{
          horizontal: isMobile ? 'center' : 'right',
          vertical: 'bottom',
        }}
      >
        {menuItems.map((item, index) => (
          <Link
            href={item.path}
            underline="none"
            sx={{
              opacity: 1,
            }}
            key={index}
            disabled={item.enabled ? !(item.enabled == 'YES') : !planData?.id}
          >
            <MenuItem
              selected={item.path.includes(router.pathname)}
              key={index}
              sx={{
                color: 'primary.purple',
                marginBottom: 2,
                px: 0,
                opacity: 1,
                fontSize: '20px',
                fontWeight: 500,
                borderRadius: '0.625rem',

                borderTopLeftRadius: '17px 15px',
                borderTopRightRadius: '70px 22px',
                borderBottomRightRadius: '30px 15px',
                borderBottomLeftRadius: '20px 27px',
                '&.Mui-selected': {
                  color: 'common.white',
                  backgroundColor: 'primary.purple',
                  '&:hover': {
                    backgroundColor: 'primary.purple',
                  },
                },
                '&.Mui-selected::after': {
                  backgroundColor: 'secondary.linen',
                },
              }}
            >
              {item.label}
            </MenuItem>
          </Link>
        ))}
        <MenuItem
          sx={{
            '&:hover': {
              backgroundColor: 'unset',
            },
          }}
        >
          <Button
            sx={{
              color: '#df4141',
            }}
            variant="contained"
            onClick={handleLogout}
            color="inherit"
            fullWidth
          >
            Logout
          </Button>
        </MenuItem>
      </Menu>
    </>
  );
}
