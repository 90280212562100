import { Box, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import HighlightedBoldTypography from './HighlightedBoldTypography';

const Section10: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Stack
      gap={2}
      pt={{ xs: 2, md: 4 }}
      px={{ xs: 2, md: 6 }}
      alignItems="center"
      textAlign="center"
    >
      <Typography
        color="#F44174"
        fontSize={{
          xs: '14px',
          md: '20px',
        }}
        lineHeight={{
          xs: '18px',
          md: '28px',
        }}
        fontWeight="bold"
      >
        How we can help
      </Typography>

      <Box width={{ xs: '100%', md: '55%' }}>
        <HighlightedBoldTypography
          fontWeight={300}
          fontSize={{ xs: '28px', md: '32px' }}
          lineHeight={{ xs: '44px', md: '55px' }}
          text="Revolutionising access to nature across urban spaces through technology
        & data."
          highlights={['technology & data.']}
        />
      </Box>
    </Stack>
  );
};

export default Section10;
