import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import Image from 'next/image';
import React from 'react';

const Section12: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const boxes = [
    {
      image: '/assets/images/new_home/image_113.png',
    },
    {
      image: '/assets/images/new_home/image_116.png',
    },
    {
      image: '/assets/images/new_home/image_117.png',
    },
    {
      image: '/assets/images/new_home/image_99.png',
    },
    {
      image: '/assets/images/new_home/image_100.png',
    },
    {
      image: '/assets/images/new_home/image_101.png',
    },
  ];

  return (
    <Box px={{ xs: 2, md: 14 }}>
      <Grid
        container
        spacing={isMobile ? 2 : 8}
        justifyContent="center"
        alignItems="center"
        sx={{
          textAlign: { xs: 'center', md: 'left' },
        }}
      >
        <Grid item xs={12} sm={6} justifyItems="center">
          <Box width={{ xs: '100%', md: '50%' }}>
            <Typography
              fontSize="32px"
              lineHeight="55px"
              fontWeight={400}
              fontFamily="Inter"
              color="#000"
            >
              Aligned to leading sustainability and wellbeing standards
            </Typography>
          </Box>
        </Grid>

        <Grid
          item
          container
          spacing={isMobile ? 2 : 4}
          justifyContent="center"
          alignItems="center"
          xs={12}
          sm={6}
        >
          {boxes.map((box, index) => (
            <Grid item key={index} xs={6} sm={4}>
              <Image
                src={box.image}
                alt="image"
                width={117}
                height={117}
                layout="contain"
                objectFit="cover"
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Box>
  );
};

export default Section12;
