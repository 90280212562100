import { Box, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import Image from 'next/image';
import { useRouter } from 'next/router';
import React from 'react';

const Section2: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const router = useRouter();

  const logos = [
    {
      src: '/assets/images/new_home/logo3.svg',
      link: 'https://aplussingapore.com/article/nalini-veeraghanta-naturethings',
      alt: 'Logo 3',
    },
    {
      src: '/assets/images/new_home/logo4.svg',
      link: 'https://www.edgeprop.sg/property-news/naturethings-marrying-ai-and-greenery-urban-spaces',
      alt: 'Logo 4',
    },
    {
      src: '/assets/images/new_home/logo1.svg',
      link: 'https://www.channelnewsasia.com/listen/daily-cuts/letter-myself-getting-roots-nalini-veeraghantas-drive-bring-more-greenery-our-urban-spaces-4787426',
      alt: 'Logo 1',
    },
    {
      src: '/assets/images/new_home/logo2.svg',
      link: 'https://fintechnews.sg/104266/payments/airwallex-impact/',
      alt: 'Logo 2',
    },
    { src: '/assets/images/new_home/logo5.svg', link: '', alt: 'Logo 5' },
    {
      src: '/assets/images/new_home/logo6.svg',
      link: 'https://www.herworld.com/pov/how-connecting-nature-can-help-our-mental-health',
      alt: 'Logo 6',
    },
  ];

  return (
    <Stack
      justifyContent="space-between"
      alignItems="center"
      textAlign="center"
      gap={{ xs: 2, md: 5 }}
      sx={{
        px: { xs: 2, md: 3 },
      }}
    >
      <Typography
        fontSize={{
          xs: '14px',
          md: '20px',
        }}
        lineHeight={{
          xs: '18px',
          md: '28px',
        }}
        fontWeight="bold"
        fontFamily="Inter"
        color="#AFAFAF"
      >
        As seen in
      </Typography>

      <Stack
        direction="row"
        justifyContent={{ xs: 'center', md: 'space-between' }}
        alignItems="center"
        gap={{ xs: 2, md: 3 }}
        flexWrap="wrap"
        width="100%"
      >
        {logos.map((logo, index) => (
          <Box
            key={index}
            sx={{
              width: { xs: '72px', md: '223px' },
              height: { xs: '40px', md: '80px' },
            }}
          >
            <Image
              src={logo.src}
              alt={logo.alt}
              width={isMobile ? 72 : 223}
              height={isMobile ? 40 : 80}
              style={{
                objectFit: 'contain',
                maxWidth: '100%',
                cursor: 'pointer',
              }}
              onClick={() => logo.link && router.push(logo.link)}
            />
          </Box>
        ))}
      </Stack>
    </Stack>
  );
};

export default Section2;
